@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/butler");

body {
  margin: 0;
  font-family: outfit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f2ee;
}
.card-border {
  border: "0.5px solid #dfdeda ";
}
.div {
  box-sizing: border-box !important;
}
.text-container {
  opacity: 0;
  transform: translateX(-100%);
  transition:
    transform 1s ease-out,
    opacity 1s ease-out;
}

.text-container.animate {
  opacity: 1;
  transform: translateX(0);
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}
